export default {
  helloApplicant: 'Hello Applicant,',
  thankYou: 'Thank you.',
  question: 'Question',
  start: 'Start',
  submitting: 'Submitting',
  submit: 'Submit',
  sending: 'Sending',
  proceed: 'Proceed',
  close: 'Close',
  poweredBy: 'Powered by',
  contactUs: 'Contact Us',
  showMore: 'Show More',
  showLess: 'Show Less',
  applying: 'Applying',
  apply: 'Apply',
  click: 'Click',
  done: 'Done',
  uploading: 'Uploading',
  cantFindPage: 'We can\'t find the page you are looking for.',
  usefulLinks: 'Here are a few links that may be useful',
  menuHome: 'Home',
  menuAboutUs: 'About Us',
  menuTnC: 'Terms and Conditions',
  menuPrivacy: 'Privacy',
  tnc1: 'By applying, you agree with ',
  tnc2: 'Interviewer.AI Candidate Terms of Use',
  amInterested: 'I am interested',
  second: 'second',
  second_plural: 'seconds',
  secondWithCount: '{{count}} second',
  secondWithCount_plural: '{{count}} seconds',
  minute: 'minute',
  minute_plural: 'minutes',
  minuteWithCount: '{{count}} minute',
  minuteWithCount_plural: '{{count}} minutes',
  workmapScreen: {
    greeting: 'Dear {{name}},',
    unansweredQuestions: 'Your unanswered questions are:',
    description: 'You are invited to participate in a WorkMap (Skill) Assessment for the role of {{jobName}} with {{companyName}}.',
    instruction: 'Instruction',
    instruction1: 'Please respond to each statement based on how you have been for the majority of your time at work.',
    instruction2: 'When responding to the statements, select the option that reflects how you generally think or feel.',
    instruction3: 'There are no right or wrong answers in this assessment, so don’t spend too much time on any one answer.',
    instruction4: 'Be spontaneous and try not to answer based on what you think we want to hear.',
    instruction5: 'Be honest in your replies. Your responses will affect the accuracy of your assessment.',
    instruction6: 'This assessment should take approximately 5 minutes to complete. Click on the "Start" button whenever you\'re ready. All the best!',
    instruction7: 'You still have unanswered questions. Please complete all questions before submitting.',
    stronglyDisagree: 'Strongly Disagree',
    disagree: 'Disagree',
    somewhatDisagree: 'Somewhat Disagree',
    neitherAgreeNorDisagree: 'Neither Agree nor Disagree',
    somewhatAgree: 'Somewhat Agree',
    agree: 'Agree',
    stronglyAgree: 'Strongly Agree',
  },
  scale: {
    'Not at all': 'Not at all',
    Rarely: 'Rarely',
    Sometimes: 'Sometimes',
    Often: 'Often',
    'Very Often': 'Very Often',
    'Strongly Disagree': 'Strongly Disagree',
    'Somewhat Disagree': 'Somewhat Disagree',
    Disagree: 'Disagree',
    'Neither Agree nor Disagree': 'Neither Agree nor Disagree',
    Neutral: 'Neutral',
    Agree: 'Agree',
    'Somewhat Agree': 'Somewhat Agree',
    'Strongly Agree': 'Strongly Agree',
  },
  applySendSuccessful: {
    thankyou: 'Thank you for your application!',
    instruction: 'We have sent you an email with the link to the interview page. We recommend you to record within the next 48 hours to maximise your chances to be invited to the next round. Good luck!',
  },
  applySuccessful: {
    congrats: 'Congrats {{name}}!',
    submitted: 'Thank you for the information provided.',
    whatsNext: 'What\'s next?',
    instruction: 'The next step in your application process is to record video responses to interview questions.',
    coachingInstruction: 'The next step in your application process is to record a video response to the assessment question.',
    instruction1: '1. Choose a quiet spot and ensure you have ample light around',
    instruction2: '2. Maintain strong network connectivity',
    instruction3: '3. Be confident and loud enough so that we can hear you clearly',
    instruction4: '4. Videos can be completed at any time before the expiry date',
    instruction5: 'We look forward to your video responses. Best of luck!',
    giveBestShot: 'This is your opportunity to showcase your personality, passion, and energy so give it your best shot!',
    helpfulTips: 'Helpful Tips:',
    doWithin48: 'Do it within 48 hours - send the link to me',
    wsgClientInstruction1:
      '5. Please note that WSG will screen all recorded videos before submitting to the employers. Videos that contain or display unsuitable or unprofessional behaviour such as use of offensive words, inappropriate background will not be submitted to employers for processing of job application(s). Job applicants will be notified to revise their recorded video and resubmit again.',
    doNow: 'Start now',
    infoProvide: 'Thank you for the informaion provided',
    opportunityText: 'This is your opportunity to showcase your personality, passion, and energy so give it your best shot!',
    quietSpot: 'Quiet spot and ensure',
    strongNetwork: 'Strong network',
    confidentLoud: 'Confident and loud enough',
    videoCompleted: 'Videos can be completed',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Thanks {{name}}!',
    submitted: 'Your application has been submitted.',
    instruction: 'Our team will review your application and will reach out to you shortly if you are being shortlisted for the next round. Note that only shortlisted candidates will be notified.',
    goodLuck: 'Wish you good luck!',
  },
  candidateProfile: {
    intro: 'Introduce yourself',
    instruction: ' Before taking the video interview, we ask that you take a moment to introduce yourself.',
    placeholder: 'Tell us about yourself',
  },
  browser: {
    worksBest: 'Interviewer.AI works best in Chrome and Firefox at the moment.',
    bestExperience: 'We are working to ensure everyone has the best experience in interviewing!',
    meantime: 'In the meantime, install the latest version of Google Chrome or Mozilla Firefox and access the same interview link for your interview:',
    downloadChrome: 'Download Chrome',
    downloadFirefox: 'Download Firefox',
    lookForward: 'We look forward to your responses!',
    bestRegards: 'Best regards,',
    interviewerTeam: 'Interviewer.AI Team',
    scanQrCode: 'Scan QR code to record on mobile',
  },
  digitalProfile: {
    interviews: 'Interviews',
    interviewName: 'Interview Name',
    status: 'Status',
    dateResponded: 'Date Responded',
    summary: 'Summary',
    topSkills: 'Top Skills',
    viewResume: 'View Resume',
    visitLinkedin: 'Visit Linkedin',
    myWorkmapScore: 'My Workmap Score',
  },
  organisationIntroVideoScreen: {
    continue: 'Continue',
    note: '(Note : Continue button will be enabled after watching the full video)',
  },
  endedScreen: {
    recordAgain: 'Record Again',
    recordAgainQuestion: 'Are you sure you want to record again?',
    recordAgainDescription: 'Do take note that this will require you to re-do the entire interview from question 1. Press the button Record Again to restart, or you can close this window and proceed to submit your responses.',
    thankyou: 'Thanks {{name}}! You have finished the interview.',
    coachingThankyou: 'Thanks {{name}}! You have finished the assessment.',
    instruction1: 'You can review your responses above before',
    instruction2: ' submitting them with the button at the bottom of the page.',
    getFeedback: 'To help us improve our product, we appreciate your feedback below! What do you think about the experience of the recording?',
    feedback: 'Feedback',
    placeholder: 'Your feedback is much appreciated!',
    rateExp: 'Rate your interview experience',
  },
  interviewCompleted: {
    submitted: 'You have submitted a response to the interview',
    thankyou: 'Thank you for your response! If you have any question, feel free to get in touch with us :)',
  },
  interviewFailure: {
    errorMessage: 'We notice that the link you accessed is invalid or does not exist. In case you have received this link from an employer, please reach out to the employer directly for a fresh link.',
    title: 'Oops, the interview link seems to be invalid!',
    contactUsBody: 'Could not access video interview link&body=Hi, I could not access the interview link: {{- link }}. It says the interview link seems to be closed by employer. Could you help me take a look?',
  },
  interviewSignUp: {
    name: 'Name',
    namePlaceholder: 'Your name',
    email: 'Email',
    emailPlaceholder: 'your_email@example.com',
    school: 'School',
    phone: 'Phone',
    linkedin: 'LinkedIn Profile',
    resume: 'Resume (pdf only)',
    errorExpired: 'Oops, the interview link seems to be expired!',
    errorMessage: 'We notice that the link you accessed is no longer available. The interview has expired and not been reactivated by the employer. In case you feel, the job is still active or the employer is still hiring, please reach out to the employer directly.',
    contactUsBody: 'Could not access video interview link&body=Hi, I could not access the interview link: {{- link }}. It says the interview link is no longer accessible. Could you help me take a look?',
    toApplyFor: 'To apply for',
    coachingToApplyFor: 'To continue with your assessment please submit details below:',
    submitInfo: ' , please submit your information below:',
    IP: {
      programOfStudy: 'Program of Study',
      school: 'School',
      formTitle: 'InsightPro Online Mock Video Interview Registration',
      instruction1: '1. Upon completing registration, you can choose either to start the video interview immediately or complete it later through the link provided via email.',
      instruction2: '2. The interview includes 8 behavioral questions and will last approximately 15 minutes.',
      instruction3: '3. Your Interview Assessment Report will be delivered to the registered email within 3 business days after the completion of the interview.',
      instruction4: '* Please note that incomplete or invalid interviews will not be reviewed and thus no assessment results will be available.',
      toApplyFor: 'To apply for',
      at: 'at',
      howYouHear: 'How did you hear about this event?',
      indicateIndustry: '(Please indicate what industry and position(s) you are interested to pursue.)',
      yearOfGrad: 'Year of Graduation',
      careerInterests: 'Career Interests',
      submitInfo: ' , please submit your information below:',
      linkedin: 'Social Media - LinkedIn',
      wechat: 'Social Media - WeChat',
      friend: 'Friend',
      infoBooth: 'Information Booths',
      searchEngine: 'Search Engine',
    },
  },
  multiAttempts: {
    title: 'Oh no, the link is valid for one-time access only!',
    errorMessage: 'We notice that the link you accessed is no longer available. It is a one time access link, if you were unable to submit the response in the earlier attempt. Please reach out to the employer directly for a fresh link or to confirm the receipt of your initial response.',
    contactUsBody: 'Interview link is valid for one-time access only&body=Hi, I could not access the interview link: {{- link }}. It says the link is valid for one-time access only. Could you help me take a look?',
  },
  preQuestionReview: {
    title: 'This is a recording of the test question',
    pleaseNote: 'Please note:',
    description1: 'While this video will not be submitted, this is how your video will appear to ',
    description2: '. Please ensure your face is visible in front of the camera and your voice is clear.',
    employer: 'employer',
    warning: 'NOTE: Once you start the interview, you will not be able to go back or restart.',
    coachingWarning: 'NOTE: Once you start the assessment, you will not be able to go back or restart.',
    instruction1: 'When you start the interview, you have only',
    coachingInstruction1: 'When you start the assessment, you have only',
    instruction2: 'to read the question and prepare for your answer. Good luck!',
    prepTime: '{{prepTime}} seconds ',
    troubleShootRedirection: 'Support link to assist and troubleshoot cam/mic issues ',
    clickLink: 'click here',
  },
  interview: {
    testAgain: 'Test again',
    startInterview: 'Start Interview',
    recordResponse: 'Record Response',
    goodLuck: 'Good luck!',
    testQuestion: 'This is a test question',
    interview: 'value',
    '10seconds': ' You have 10 seconds to answer this question.',
    allowDevices: 'You need to allow the browser to access the camera and microphone.',
    done: 'Done',
    preparation: 'Preparation',
    remainingTime: 'Remaining Time',
    back: 'Back',
    checkYourVideo: 'Check your video',
    okayLetsProceed: 'Okay, let\'s proceed',
    startPractice: 'Start Practice',
    importantNotes: 'Important Notes:',
    instruction1: 'First, test with a practice question',
    instruction2: 'It won\'t count as a submission',
    instruction3: 'Click Start Test to begin',
    startTest: 'Start Test',
    alreadyApplied: 'Already applied!',
    alreadyAppliedToTheJob: 'You have already applied to this job using',
    clickBelowToProceed: 'Click below to proceed.',
    continue: 'Continue',
    blockerHeading: 'You need to turn on your microphone and video permissions to move forward.',
    blockerPrimaryText: 'To turn on microphone and video permissions please follow the following steps.',
    forChrome: 'For Chrome:',
    chromeBlockerStep1: '1) At the top right, click More. Settings.',
    chromeBlockerStep2: '2) Click Privacy and security Site settings. Camera or Microphone.',
    forFireFox: 'For Firefox:',
    firefoxBlockerStep1: '1) Click the menu button and select Settings.',
    firefoxBlockerStep2: '2) Click Privacy & Security from the left menu.',
    firefoxBlockerStep3: '3) Scroll down to the Permissions section.',
    firefoxBlockerStep4: '4) Click the Settings button for the Microphone and video option',
  },
  preQuestion: {
    qtitle: 'Tell us your favourite colour and why?',
    title1: 'This is a',
    title2: ' practice question ',
    title3: 'to test your video and audio.',
    notSubmitted: 'It won\'t be added to your submission.',
    toTest: 'to test',
    instruction1: ' Once you are comfortable with the recording experience, click',
    instruction2: 'at the bottom to proceed.',
    warning: 'NOTE: You only have 1 attempt for this interview. Please use this question to test your voice and video before you start the actual interview.',
    '10seconds': ' You have 10 seconds to answer this question.',
    allowDevices: 'You need to allow the browser to access the camera and microphone.',
    numOfQuestions: 'There are a total of {{numberOfQuestions}} questions in this interview.',
    troubleShootRedirection: 'Support link to assist and troubleshoot cam/mic issues ',
    clickLink: 'click here',
  },
  recording: {
    howManySeconds: 'You have {{seconds}} seconds to answer this question.',
    recordingStatus: 'Recording',
    preparationStatus: 'Preparation',
  },
  submitFailure: {
    ohNo: 'Oh no!',
    description: 'We encountered some failure in submitting your response. This is usually due to intermittent network connection that causes the submission to drop off. We apologize for the inconvenience.',
    instruction1: 'Unfortunately, we were unable to capture your recorded answers successfully.',
    instruction2: 'Please take a moment to re-record your responses for these questions.',
    instruction3: 'If you continue to face challenges after resubmitting, feel free to reach out to us at ',
    instruction4: 'You can also re-record your responses for these questions on other device by scanning the below qrcode.',
    recordAgain: 'Record Again',
    networkStatus: 'Network status',
    connected: ' Connected',
    notConnected: ' Not connected',
    check1: 'Check if you ',
    check2: 'have access to internet here.',
    reSubmit: 'Re-submit',
  },
  submittedScreen: {
    title: 'Your response has been submitted!',
    thankyou: 'Thank you for your precious time to interview with us, we hope you enjoyed the experience!',
    redirect1: 'You will now be redirected in {{time}} seconds...',
    orClick: 'Or click ',
    here: 'here',
    ifNotRedirected: ' if you\'re not redirected',
  },
  submittingScreen: {
    title: 'We are submitting your responses, please do not close your browser...',
  },
  landing: {
    dear: 'Dear {{name}},',
    hi: 'Hi {{name}},',
    previous: 'Previous',
    next: 'Next',
    description: 'As part of the online interview, you are required to complete a video interview for the role of',
    coachingDescription: 'As part of the online assessment, you are required to record a video response(s) to the assessment question(s)',
    with: 'with ',
    at: 'at',
    quietSpot: 'Find a quiet spot with ample lighting and strong WiFi to take your assessment.',
    instructions: 'Instructions',
    instruction1: 'This video assessment consists of',
    instruction1a: 'questions(s) and should take you',
    instruction1b: 'to complete, depending on the number of questions you are assigned.',
    instruction2: 'You are required to complete your assessment within a single sitting.',
    instruction3: 'Once you are presented with your first question, an automated timer starts. You will be given a preparation time of ',
    instruction3a: ', followed by a recording time limit that may vary for each question.',
    instruction4: 'Remember to keep track of the timer while preparing or recording your response. Should you find yourself ready before the time limit, you can choose to either start recording your responses or to submit them beforehand.',
    coachingInstruction5: 'Please Note: Do not refresh, close or exit the page once the assessment has started, as you would not be allowed to access the assessment link anymore.',
    instruction5: 'Please Note: Do not refresh, close or exit the page once the interview has started, as you would not be allowed to access the interview link anymore.',
    instruction6: 'Don\'t worry, you will be able to review your video responses at the end of the entire assessment. If you feel that your responses are unsatisfactory, you have the choice to retake the entire assessment',
    instructionRedo: '* However, the order and nature of questions are subject to change to ensure you come across as natural and spontaneous during the video interview.',
    envCheck: 'Equipment/Environment Check',
    env1: 'Find a quiet spot with ample lighting and strong WiFi to take your assessment.',
    env2: 'Interview recording only works in Chrome and Firefox at the moment. Do ensure that you are using either of these browsers to take your assessment.',
    env3: 'To proceed with the video interview, we will require access to your video camera and your microphone.',
    coachingEnv3: 'To proceed with the video assessment, we will require access to your video camera and your microphone.',
    env4: 'You will be shown a You will be shown a question where you can test your audio and video quality before starting the actual assessment.',
    env5: 'Be sure to position yourself well within the frame of your screen, sit upright and look straight into your camera when you present your responses.',
    env6: 'You will be able to review your video responses at the end of the entire assessment. If you feel that your responses are unsatisfactory, you have the choice to retake the entire assessment.',
    readyClick: 'Click on the "Proceed" button whenever you\'re ready. All the best!',
    instruction7: 'Please upload the right identification documents as mandated (Govt ID etc.)',
    resumeOnly: 'Resume (pdf, doc and docx only) *',
    error: 'Please upload pdf file only. Rejected files',
    env7: 'Don\'t toggle or switch between your browsers while recording.',
    env8: 'Maintain your eye contact and do not distract yourself too much while recording your responses.',
    env9: 'The audio is getting picked up while you record. Make sure you are the only person speaking during the interview recording.',
  },
  resumeParser: {
    title: 'Resume Parser Demo',
    description: 'Upload a resume below to see how our system parse and evaluate the candidate\'s resume',
    dragResume: 'Drag and drop a resume here, or click to select file (pdf only)',
    parsedResults: 'Parsed results',
    experience: 'Experience',
    personalDetails: 'Personal Details',
    totalYoE: 'Total Years of Experience',
    company: 'Company',
    position: 'Position',
    duration: 'Duration',
    skills: 'Skills',
    education: 'Education',
    institute: 'Institute',
    degree: 'Degree',
    major: 'Major',
    score: 'Score',
    overall: 'Overall',
    betterFitNote: 'Note: Candidates with an Overall resume score of 55-100 are a better fit based on the assigned Job Description and Requirements.',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
  },
  countdown: {
    recordIn: 'Record in',
  },
  prepTimer: {
    timer: 'Prep time',
  },
  candidateDetails: {
    back: 'Back',
    download: 'Download',
    questions: 'Questions',
    videoTranscript: 'Video Transcript',
    resumeScore: 'Resume Score',
    workmapScore: 'Workmap Score',
    aiVideoScore: 'AI Video Score',
    videoAssessment: 'Video Interview Assessment',
    text: 'Text',
    voice: 'Voice',
    facialExpression: 'Facial Expression',
    professionalism: 'Professionalism',
    energyLevel: 'Energy Levels',
    communication: 'Communication',
    sociability: 'Sociability',
    pace: 'Pace',
    charity: 'Clarity',
    sentiments: 'Sentiment',
    energy: 'Energy',
    positivity: 'Positivity',
    positiveEmotion: 'Positive Emotion',
    aboutThisAssessmentReport: 'About This Assessment Report',
    introduction: 'Introduction',
    useOfReport: 'Use of Report',
    confidentialityDisclaimer: 'Confidentiality & Disclaimer',
    workmapAssessment: 'Workmap Asssessment',
    actualScore: 'Actual Scores',
    resumeScoring: 'Resume Scoring',
    excellent: 'Excellent',
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    glossaryIndex: 'Glossary/Index',
    definitionOfVideoInterviewParameter: 'Definition of Video Interview parameters',
    candidateSummary: 'Candidate Summary',
    notAvailable: 'Not Available',

  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Please complete your video interview to view the assessments.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Your video interview is currently being assessed. Please come back again shortly!',
    '404_CANDIDATE_NOT_AVAILABLE': 'You have not taken any video interviews yet. Please come back after you have submit your video interview response.',
    default: 'An error has occured. Please try again later.',
  },
  dashboardPanel: {
    dashboard: 'Dashboard',
    hey: 'Hey',
    morningGreeting: 'Good Morning',
    afternoonGreeting: 'Good Afternoon',
    eveningGreeting: 'Good Evening',
    upgrade: 'Upgrade',
    support: 'Support',
    signOut: 'Sign Out',
    collapse: 'Collapse',
    faqs: 'FAQs',
    company: 'Company',
    role: 'Role',
    status: 'Status',
    action: 'Action',
    date: 'Date',
    recordNow: 'Record Now',
    responded: 'Responded',
    processing: 'Processing',
    viewDetails: 'View Details',
    personalInfo: 'Personal Info',
    introVideo: 'Intro Video',
    score: 'Score',
    email: 'Email',
    phone: 'Phone',
    summary: 'Summary',
    topSkill: 'Top Skills',
    workmapScore: 'Workmap Score',
    aiVideoScore: 'AI Video Score',
    introText1: 'Get ready for exciting insights. Start with recording',
    introText2: 'your intro video.',
    recordVideo: 'Record Video',
  },
  purchaseBanner: {
    heading: 'Upgrade to view your detailed AI interview report',
    subHeading: 'Be confident in your next round of interviews.',
    launchAt: 'Launch offer at',
    only: 'Only',
    year: 'year',
  },
  companyDetails: {
    company: 'Company',
    about: 'About',
    experience: 'Experience',
    apply: 'Apply',
    description: 'Description',
    viewJobDetailText: 'View Job Details',
  },
  additionalFormField: {
    chooseFileBtn: 'Choose file',
    pleaseChooseAFileText: 'Please choose a file',
  },
  quitInterviewModal: {
    confirmQuit: 'Confirm Quit Interview',
    areYouSure: 'Are you sure you want to quit the interview?',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  processingVideo: {
    instruction1: 'Processing your video...',
    instruction2: 'Please wait',
    instruction3: 'It will only take few seconds...',
    instruction4: 'Be patient.',
    noAudioFound: 'No Audio found please re-record with clear Audio. Please check your mic connection.',
  },
  audioError: {
    message: 'We regret there was an error while transcribing your video. Please check your audio and try again.',
  },
};
